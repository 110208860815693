import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { useSearchClassesForDashboard } from "../hooks";

export const WarmupsStretchesAndCooldowns = () => {
  const { classResults: warmupClasses, loading } = useSearchClassesForDashboard(
    {
      defaultFilters: ["type:'Warm-Ups, Stretches, & Cooldowns'"],
    }
  );

  if (warmupClasses?.length === 0) {
    return null;
  }

  return (
    <Section
      title="Warm-Ups, Stretches, & Cooldowns"
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__classes}?refinementList[type][0]=Warm-Ups%2C%20Stretches%2C%20%26%20Cooldowns`,
        text: "See All",
      }}
    >
      <Slider
        data={warmupClasses}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.WarmupsAndStretches}
            selectedFrom="WarmUpsStretchesAndCooldowns"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
